import React from "react";

import Layout from "../components/layout-main";
import { ContactForm, FormTypes } from "../components/form-contact";
import { SocialIcons } from "../components/social-icons";
import { Link, Grid } from "theme-ui";
import { CalendlyEventTypes } from "../components/form-calendly";

const ContactPage = ({ location }) => {
  return (
    <Layout location={location} pageTitle="Contact Us">
      <section className="aw-accent">
        <div className="aw-content">
          <h1>Contact Us</h1>

          <Grid gap={[0, 2]} columns={["none", "6fr 1fr 5fr"]}>
            <div>
              <ContactForm
                formSubtitle=""
                formOutro="Thanks. We’ll reach out soon for more details on how we can help."
                buttonVariant="blueButton"
                buttonText="Submit"
                formType={FormTypes.GENERAL_CONTACT}
                calendlyType={CalendlyEventTypes.CONSULTATION}
              />
            </div>
            <div></div>
            <div>
              <h2>Email</h2>
              <p>
                <Link sx={{ fontSize: 3 }} href="mailto:contact@afterword.com">
                  contact@afterword.com
                </Link>
              </p>

              <h2>Phone</h2>
              <p>
                <Link
                  sx={{ fontSize: 3 }}
                  target="_blank"
                  href="tel:8552811851"
                >
                  (855) 281-1851
                </Link>
              </p>

              {/* <h2>Social Media</h2>
              <SocialIcons /> */}

              <h2>Media Inquiries</h2>
              <p>
                <Link sx={{ fontSize: 3 }} href="mailto:media@afterword.com">
                  media@afterword.com
                </Link>
              </p>
            </div>
          </Grid>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
